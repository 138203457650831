<template>
  <div class="flex items-center">
    <Icon
      v-for="point in pointsFull"
      :key="`heart-full-${point}`"
      graphic="heart-full"
      size="md"
      class="text-txt-400"
    />

    <Icon
      v-for="point in pointsEmpty"
      :key="`heart-${point}`"
      graphic="heart"
      size="md"
      class="text-txt-400"
    />
  </div>
</template>

<script lang="ts">
import { Icon } from 'webcc-ui-components'

export default defineComponent({
  name: 'BasicRatingHearts',

  components: { Icon },

  props: {
    points: {
      type: Number,
      required: true,
    },
  },

  MAX_POINT: 5,

  computed: {
    pointsFull() {
      return Math.ceil(this.points)
    },

    pointsEmpty() {
      return this.$options.MAX_POINT - this.pointsFull
    },
  },
})
</script>
