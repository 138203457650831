import {
  useInfiniteQuery,
  useQuery,
  useQueryClient,
  type InfiniteData,
} from '@tanstack/vue-query'
import { pick } from 'lodash-es'
import type { MaybeRef } from 'vue'
import type { components } from '~/schema'

export type LastReviewResponse = components['schemas']['ReviewDetails']

export function useLastReviewQuery() {
  const fetch = useApiFetch()
  return useQuery({
    queryKey: ['last-review'],
    queryFn({ signal }) {
      return fetch<LastReviewResponse>('/api/reviews/last', {
        signal,
      })
    },
  })
}

export type ReviewsResponse = components['schemas']['ReviewsList']
export type ReviewsPagination = Required<
  Pick<ReviewsResponse, 'page' | 'size' | 'total' | 'rest'>
>
const defaultPagination: ReviewsPagination = {
  page: 0,
  size: 12,
  total: 0,
  rest: 0,
}
export type Review = components['schemas']['ReviewDetails']

export function useReviewsQuery(accommodationId: MaybeRef<string | undefined>) {
  const fetch = useApiFetch()
  return useInfiniteQuery({
    queryKey: ['reviews', accommodationId] as const,
    initialPageParam: 0,
    async queryFn({ signal, pageParam = 0, queryKey: [_, accommodationId] }) {
      return await fetch<ReviewsResponse>('/api/reviews', {
        signal,
        query: {
          page: pageParam,
          size: defaultPagination.size,
          accommodationID: toValue(accommodationId),
        },
      })
    },
    getNextPageParam(lastPageData, _allPages, lastPageParam) {
      if (!lastPageData.rest) return
      return lastPageParam + 1
    },
    select({ pages }) {
      const reviews = pages.reduce(
        (acc, current) => acc.concat(current.content || []),
        [] as Review[],
      )

      return {
        reviews,
        averageRating: pages[pages.length - 1].averageRating || 0,
        pagination: pick(pages[pages.length - 1] || defaultPagination, [
          'page',
          'size',
          'total',
          'rest',
        ]) as ReviewsPagination,
      }
    },
  })
}

export function useReviewTargetList() {
  const fetch = useApiFetch()
  return useQuery({
    queryKey: ['translations-review'] as const,
    staleTime: 12 * 60 * 60 * 1000, // 12h stale time
    queryFn({ signal }) {
      return fetch<string[]>('/api/reviews/translation/languages/target', {
        signal,
      })
    },
  })
}

export type ReviewTranslationResponse =
  components['schemas']['ReviewTranslation']

export enum ReviewTranslationState {
  Progress = 'progress',
  Error = 'error',
  Success = 'success',
  None = 'none',
}

export function useReviewTranslation(params: {
  review: MaybeRef<Review>
  language: MaybeRef<string>
  accommodationId: MaybeRef<string | undefined>
}) {
  const fetch = useApiFetch()
  const queryClient = useQueryClient()
  const { locale } = useI18n()

  const queryResult = useQuery({
    enabled: computed(() => unref(params.language) === unref(locale)),
    queryKey: ['translations-review', unref(params.review).id, locale] as const,
    staleTime: 12 * 60 * 60 * 1000, // 12h stale time
    queryFn({ queryKey: [_, reviewId, locale], signal }) {
      return fetch<ReviewTranslationResponse>(
        `/api/reviews/${reviewId}/translation/${locale}`,
        {
          signal,
        },
      )
    },
  })

  watch(queryResult.data, (newData) => {
    if (
      newData &&
      newData.commentDetectedLang !== unref(params.review).language
    ) {
      queryClient.setQueryData<InfiniteData<ReviewsResponse>>(
        ['reviews', toValue(params.accommodationId)],
        (pageData) => {
          if (!pageData) return pageData
          return {
            ...pageData,
            pages: pageData.pages.map((page) => {
              return {
                ...page,
                content: page.content!.map((review: Review) => {
                  if (review.id !== unref(params.review).id) return review
                  return {
                    ...review,
                    language: newData.commentDetectedLang,
                  }
                }),
              }
            }),
          }
        },
      )
    }
  })

  return queryResult
}
